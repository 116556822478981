<template>
  <div class="index-pin">
    <div
      class="headline"
      v-html="$t('terminal.index.pin.message')"
    ></div>
    <div
      class="description"
      v-html="$t('terminal.index.pin.description')"
    ></div>

    <!-- form:pin START -->
    <el-form
      status-icon
      :rules="rules"
      ref="pinForm"
      :model="pinForm"
      class="pin-form"
      :statusIcon="false"
    >

      <!-- form:pin:mask START -->
      <div class="mask-holder">
        <div
          :class="[
            'mask-pin',
            { 'has-error' : maskPinError }
          ]"
          v-html="maskPin"
        ></div>
        <div
          class="mask-pin-error"
          v-if="maskPinError"
          v-html="$t('terminal.global.error.pin')"
        ></div>
      </div>
      <!-- form:pin:mask END -->

      <!-- form:pin:pin START -->
      <el-form-item prop="pin">
        <el-input
          class="pin"
          type="number"
          inputmode="numeric"
          :placeholder="`${$t('terminal.index.pin.message')} *`"
          v-model="pinForm.pin"
        ></el-input>
      </el-form-item>
      <!-- form:pin:pin END -->

      <!-- form:numpad START -->
      <numpad
        :updatePin="updatePin"
        :deleteOne="deleteOne"
        :deleteAll="deleteAll"
        :touchStart="touchStart"
        :touchEnd="touchEnd"
        :changeColor="changeColor"
        :pin="pinForm.pin"/>
      <!-- form:numpad END -->
    </el-form>
    <!-- form:pin END -->

    <!-- index:qr-code:ec START -->
    <div class="help-ec">
      <div class="help-ec-inner">
        <div class="actions">
          <!-- abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
          >
            <span v-html="$t('terminal.index.cart.purchase.abort')"></span>
          </div>
          <!-- abort END -->
        </div>
      </div>
    </div>
    <!-- index:qr-code:ec END -->

  </div>
</template>

<script>
export default {
  name: 'cart-pin',
  props: {
    step: {
      type: Number,
    },
    forward: {
      type: Function,
    },
    user: {
      type: Object,
    },
    resetStoreCart: {
      type: Function,
    },
    changeColor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Numpad: () => import('../../components/index/Numpad.vue'),
  },
  data() {
    return {
      userPin: null,
      maskPin: '<span></span><span></span><span></span><span></span>',
      maskPinError: false,

      /**
       * Form (Models) + Pass data to mixin
       */
      pinForm: {
        pin: '',
      },

      /**
       * Form (Rules - Validation)
       */
      rules: {
        pin: [
          {
            required: true,
            pattern: /^[^-\s][a-zA-Z0-9_\s-]+$/,
            message: this.$t('terminal.index.pin.error.message'),
            trigger: [
              'blur',
            ],
          },
          {
            pattern: /^\d{4}$/,
            message: this.$t('terminal.index.pin.error.message'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  async created() {
    this.userPin = await this.FETCH_PIN();
  },
  methods: {
    async FETCH_PIN() {
      await this.$store.dispatch('setPin');
      return this.$store.getters.pin;
    },

    updatePin(number) {
      let activePin = String(this.pinForm.pin);

      // mask (add index)
      const maskPinSpans = document.querySelectorAll('.mask-pin span');
      maskPinSpans.forEach((span, index) => {
        if (index === activePin.length) {
          span.classList.add('active');
          // mask (error)
          this.maskPinError = false;
        }
      });

      // pin
      activePin += number;
      // this.pinForm.pin = Number(activePin);
      this.pinForm.pin = activePin;
      if (activePin.length === 4) {
        this.submitForm('pinForm');
      }

      // shake area
      if (activePin.length >= 1 && activePin.length <= 3) {
        const maskPinHolder = document.querySelector('.mask-holder');
        maskPinHolder.classList.remove('shake');
      }
    },

    deleteOne() {
      let activePin = String(this.pinForm.pin);

      // pin
      activePin = activePin.slice(0, -1);

      // mask (remove index)
      const maskPinSpans = document.querySelectorAll('.mask-pin span');
      maskPinSpans.forEach((span, index) => {
        if (index === activePin.length) {
          span.classList.remove('active');
        }
      });
      if (activePin.length >= 1) {
        this.pinForm.pin = Number(activePin);
      } else {
        this.pinForm.pin = '';
        // mask (remove all)
        maskPinSpans.forEach((span) => {
          span.classList.remove('active');
        });
      }

      // mask (error)
      this.maskPinError = false;
    },

    deleteAll() {
      this.pinForm.pin = '';
      // mask (remove index)
      const maskPinSpans = document.querySelectorAll('.mask-pin span');
      maskPinSpans.forEach((span) => {
        span.classList.remove('active');
      });
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'pinForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            setTimeout(() => {
              if (this.userPin.pinCode.toString() === this.pinForm.pin) {
                this.maskPinError = false;

                formData.append('locale', localStorage.getItem('boxLang'));
                formData.append('pinCode', this.pinForm.pin);

                // step forward
                this.forward(this.step, true);
              } else {
                // shake area
                const maskPinHolder = document.querySelector('.mask-holder');
                maskPinHolder.classList.add('shake');

                // mask (error)
                this.maskPinError = true;

                // clear pin area
                this.deleteAll();
              }
            }, 350);
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
};
</script>
